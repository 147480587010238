<template>
  <div class="container pb-2 d-flex flex-column ios-wrap">
    <router-link class="btn btn-primary my-3" to="/hidden/settings">
      Kiitos, olen nähnyt tarpeeksi. 👀
    </router-link>

    <div v-if="!choiceMade" class="w-100">
      <p>Mikä puhelin sinulla on?</p>
      <div class="w-100 d-flex justify-content-center">
        <button
          class="btn btn-success mr-2"
          @click="
            choiceMade = true;
            iphone = true;
          "
        >
          iPhone 😇</button
        ><button
          class="btn btn-danger"
          @click="
            choiceMade = true;
            iphone = false;
          "
        >
          Android 🤮
        </button>
      </div>
    </div>

    <div v-else>
      <div v-if="iphone">
        <Carousel device="ios" :max="3" />
      </div>
      <div v-else>
        <Carousel device="android" :max="4" />
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/tutorial/Carousel.vue";

export default {
  name: "Tutorial",
  data: () => ({
    choiceMade: false,
    iphone: true,
  }),
  components: { Carousel },
};
</script>
