<template>
  <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators" style="mix-blend-mode: difference">
      <li
        data-target="#carouselIndicators"
        :class="{ active: i === 1 }"
        v-for="i in max"
        :key="i"
        :data-slide-to="i - 1"
      ></li>
    </ol>
    <div class="carousel-inner">
      <div
        class="carousel-item"
        :class="{ active: i === 1 }"
        v-for="i in max"
        :key="i"
      >
        <img
          :src="require('@/assets/instructions/' + device + '/' + i + '.png')"
          class="d-block w-100"
        />
      </div>
    </div>
    <a
      class="carousel-control-prev"
      style="mix-blend-mode: difference"
      href="#carouselIndicators"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a
      class="carousel-control-next"
      style="mix-blend-mode: difference"
      href="#carouselIndicators"
      data-slide="next"
    >
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: ["max", "device"],
};
</script>
